import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { apiRoutes } from '../../../api';
import { useRequest } from '../../../hooks';
import { useEffect, useState, useMemo } from 'react';
import styles from './PNRValidateForm.module.css'
import tache from '../../../assets/images/tache.png';
import palomita from '../../../assets/images/palomita.png';
import Spinner from 'react-bootstrap/Spinner';
import useInterval from '@use-it/interval';


function PNRValidateForm() {
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [messageId, setMessageId] = useState(null);
  const [delay, setDelay] = useState(5000);
  const [cargando, setCargando] = useState(false);
  //El segundo servicio puede regresar mensaje que indique error, aunque este no sea un error del servicio
  //Por eso necesitamos otra bandera para poder pintar la caja del color correcto
  const [valido, setValido] = useState(true);

  const { 
    register, 
    handleSubmit, 
    formState: { errors, isDirty, isSubmitting, touchedFields, submitCount }
  } = useForm();

  const [validateAgenciaFn, { data, error, isLoading}] = useRequest({
    url: `${apiRoutes.CHANGE_CLASE}`,
    method: 'PUT',
  });


  const [validateMessageFn, { data:data_estatus, error:error_estatus, isLoading:isLoading_estatus}] = useRequest({
    url: `${apiRoutes.CONFIRM_CLASE.replace(':messageId',messageId)}`,
    method: 'GET',
  });

  

  const onSubmit = info => {
    setCargando(true);
    setMessage(null);
    setDelay(5000);
    validateAgenciaFn({
      pnr: info.pnrInput
    });

    return navigate("/pnr/validate");  
  }
  
  //Ejecutar cada X tiempo hasta que delay sea puesto en null
  useInterval(() => {
    if(messageId!=null){
      validateMessageFn({

      });
      
    }
  }, delay);

  useEffect(() => {
   
    if(data_estatus){
      //Ya terminó el proceso, pasar delay a null para detener el intervalo que repite la petición
      //Mandar mensaje de la respuesta al usuario
      if(data_estatus.finalizado){
        setDelay(null)
        //El proceso fue exitoso?
        if(data_estatus.exito){
          setValido(true)
        }
        else{
          setValido(false)
        }
        
        setMessage(data_estatus.resultado)
        setCargando(false);
      }
      
    }
    if(error_estatus){
      //Mapear errores
      switch(error_estatus.status){
        case 400 :
          setMessage("Error en el código ingresado");
        break;

        case 401:
          setMessage("No tiene permisos de actualizar el PNR");
        break;

        case 404:
          setMessage("Pnr no existe");
        break;

        default:
          setMessage("Ocurrió un error al hacer el cambio de clase");

      }
      setCargando(false);
      //Si sale error dejar de hacer peticiones
      setDelay(null)
      setMessageId(null)

    }

  }, [data_estatus, error_estatus])

  useEffect(() => {
    
    if (data) {
      //Guardamos el messageid que usaremos para consultar el otro servicio
      setMessageId(data.messageId);
     
    }
    if(error){
      //Mapear errores
      switch(error.status){
        case 400 :
          setMessage("Error en el código ingresado");
        break;

        case 401:
          setMessage("No tiene permisos de actualizar el PNR");
        break;

        case 404:
          setMessage("Pnr no existe");
        break;

        default:
          setMessage("Ocurrió un error al hacer el cambio de clase");

      }
      //Pasar estado a no loading y remover message id en caso de que esté guardado de una peticion anterior
      setCargando(false);
      setMessageId(null);
           

    }
  }, [data,error,messageId])

  return (
    <main className="form-signin w-100 h-100 m-auto d-flex justify-content-center align-items-center">
      <div className="wrapper m-auto form-card">
        <div className="card-title lightblue-bg mb-0 text-center py-4">
          <h1 className="h3">Valida PNR</h1>
        </div>
        <form id="loginform" onSubmit={handleSubmit(onSubmit)}>
          <div className={`${(error && cargando==false) || (error_estatus  && cargando==false) || (!valido && cargando==false)?styles.errorMessage:""} ${data && data_estatus && cargando==false && valido?styles.successMessage:""} ${styles.mensaje}`}>
          {message&& error|| (message && !valido) || (message && error_estatus)?<img className='icono-message' src={tache} />  :'' }
          {message&& data_estatus && valido?<img  className='icono-message' src={palomita} /> :''  }
          {message}
          </div>
          
          <div className={errors.pnrInput ? 'form-control is-invalid mb-3': 'form-control mb-4'}>
            <label htmlFor="pnrInput">PNR</label>
            <input type="text"
            className={errors.pnrInput ? 'form-control is-invalid': 'form-control'} 
            {...register("pnrInput", { 
              required: true ,
              pattern: /^[A-Za-z]+$/g ,
              maxLength: 6,
              minLength:6
            })}
            id="pnrInput"
            
            aria-describedby="pnrInputHelp" />
            {errors.pnrInput?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
            {errors.pnrInput?.type === 'pattern' && <p className="invalid-feedback mb-0">Solo se permiten letras</p>}
            {errors.pnrInput?.type === 'maxLength' && <p className="invalid-feedback mb-0">El código debe tener 6 caracteres</p>}
            {errors.pnrInput?.type === 'minLength' && <p className="invalid-feedback mb-0">El código debe tener 6 caracteres</p>}
            
          </div>
          
          <Button variant="secondary" size="lg" className={errors.pnrInput ? 'text-uppercase is-invalid mt-3 w-100': 'text-uppercase mt-3 w-100'} type="submit">
            Aceptar
            {cargando?<Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>:""}
            </Button>
          <p className="mt-3 mb-3 text-muted text-center fs-6">
            Global Sales Support/TI Comercial <br />
            Todos los derechos reservados <br />
            &copy; Aeroméxico 2022 <br />
            <small>v1.0.8</small>
          </p>
        </form>
      </div>
      <style jsx>{`
       button.is-invalid,
       button.is-invalid:hover,
       button.is-invalid:focus,
       button.is-invalid:active
        {
        background: #E91B2F;
        border:0
      }
      .icono-message {
        margin-right: 10px;
        vertical-align: sub;
      }

      .spinner-border {
        margin-left: 10px;
        width: 20px;
        height: 20px;
    }
      `}</style>
    </main>
    
  
  );
}
export default PNRValidateForm;
