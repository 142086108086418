import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";
import { useRequest, useAuth } from '../../../hooks';
import { apiRoutes } from "../../../api";

const LoginForm = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showMsgError, setShowMsgError] = useState(false);
  const [loginFn, { data, error: errorLogin, isLoading }] = useRequest({
    url: apiRoutes.LOGIN,
    method: 'POST',
  });

  const { 
    register, 
    handleSubmit, 
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    const { userName, password } = data;
    loginFn({ correo: userName, contrasenia: password })
  }
  useEffect(() => {
    const rol = localStorage.getItem('@csi/rol');
    const token = localStorage.getItem('@csi/token');
    if (token) {
      if (rol === 'ADMINISTRADOR' || rol === 'CONSULTA') {
        navigate("/agency/list");
      } else if (rol === 'AGENCIAS') {
        navigate("/pnr/validate");
      }
    } else {
      localStorage.removeItem('@csi/rol');
      localStorage.removeItem('@csi/token');
    }
  }, [])

  useEffect(() => {
    if (data) {
      login(data);
      if (data.perfil === 'ADMINISTRADOR' || data.perfil === 'CONSULTA') {
        navigate("/agency/list");
      } else {
        navigate("/pnr/validate");
      }
    }
  }, [data]);

  useEffect(() => {
    if (errorLogin) {
      const { error: {response: { data }} } = errorLogin;
      if (data?.errorDetail && data.errorDetail[0] === "FORCE_CHANGE_PASSWORD") {
        navigate("/change/password");
      } else {
        setShowMsgError(true);
      }
    }
  }, [errorLogin]);
  
  return (
    <main className="form-signin w-100 h-100 m-auto d-flex justify-content-center align-items-center">
      <div className="wrapper m-auto form-card">
        {showMsgError && <Alert variant='danger' onClose={() => setShowMsgError(false)} dismissible>El usuario o contraseña son incorrectos</Alert>}
        <div className="card-title lightblue-bg mb-0 text-center py-4">
          <h1 className="h3" id="iniciar-sesion">Iniciar Sesión</h1>
        </div>
        <form id="loginform" onSubmit={handleSubmit(onSubmit)}>
          <div className={errors.userName ? 'form-control is-invalid mb-3': 'form-control mb-4'}>
            <label htmlFor="userInput">Usuario</label>
            <input type="text"
              className={errors.userName ? 'form-control is-invalid': 'form-control'} {...register("userName", {
                required: true,
                pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              })}
              id="userInput"
              aria-describedby="userNameHelp"
            />
            {errors.userName?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
            {errors.userName?.type === 'pattern' && <p className="invalid-feedback mb-0">Ingresa un email válido</p>}
          </div>
          <div className={errors.password ? 'form-control is-invalid mb-4': 'form-control mb-4'}>
            <label htmlFor="passwordInput">Contraseña</label>
            <input 
              type="password"
              className={errors.password ? 'form-control is-invalid': 'form-control'} {...register("password", { required: true })}
              id="passwordInput"
              aria-describedby="passwordInputHelp"
            />
              {errors.password?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
          </div>
          <a href="/reset/password">Olvidé mi contraseña</a>
          <Button
            variant="success"
            size="lg"
            className=
              {
                errors.userName ?
                  'text-uppercase is-invalid mt-3 w-100':
                  'text-uppercase mt-3 w-100'
              }
            type="submit">
              {isLoading &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              }
              {!isLoading && 'Iniciar sesión'}
          </Button>
          <p className="mt-3 mb-3 text-muted text-center fs-6">
            Global Sales Support/TI Comercial <br />
            Todos los derechos reservados <br />
            &copy; Aeroméxico 2022 <br />
            <small>v1.0.8</small>
          </p>
        </form>
      </div>
    </main>
  );
}
export default LoginForm;
