import { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useRequest } from '../../../hooks';
import { apiRoutes } from "../../../api";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const [showConfirmForm, setShowConfirmForm] = useState(false);
  const [email, setEmail] = useState('');
  const [showMsgError, setShowMsgError] = useState(false);
  const [msgErrorConfirm, setMsgErrorConfirm] = useState([]);
  const [resetPasswordFn, { data, error, isLoading }] = useRequest({
    url: apiRoutes.RESET_CRED,
    method: 'PUT',
  });

  const [confirmPasswordFn, { data: confirmPasswordData, isLoading: isLoadingConfirm, error: errorConfirm }] = useRequest({
    url: apiRoutes.CONFIRM_CRED,
    method: 'PUT',
  });

  const { 
    register, 
    handleSubmit, 
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    setEmail(data.userName);
    resetPasswordFn({ correo: data.userName });
  };

  const onSubmitConfirm = (data) => {
    confirmPasswordFn({
      correo: email,
      codigo: parseInt(data.code, 10),
      nuevaContrasenia: data.password,
    });
  }

  useEffect(() => {
    if (data) {
      setShowConfirmForm(true);
    }
  }, [data]);

  useEffect(() => {
    if (confirmPasswordData) {
      navigate('/');
    }
  }, [confirmPasswordData])

  useEffect(() => {
    if (error) {
      setShowMsgError(true)
    }
  },[error])

  useEffect(() => {
    if (errorConfirm) {
      if (errorConfirm.error.response && errorConfirm.error.response.data && errorConfirm.error.response.data.errorDetail) {
        setMsgErrorConfirm(errorConfirm.error.response.data.errorDetail)
      }
      setShowMsgError(true);
    }
  }, [errorConfirm])

  const formEmail = () => (
    <form id="loginform" onSubmit={handleSubmit(onSubmit)}>
      {showMsgError &&
        <Alert
          variant='danger'
          onClose={() => setShowMsgError(false)}
          dismissible
        >
          {error && 'El correo que ingresaste no existe, por favor verifícalo'}
        </Alert>
      }
      <div className={errors.userName ? 'form-control is-invalid mb-3': 'form-control mb-4'}>
        <label htmlFor="userInput">Correo</label>
        <input type="text"
          className={errors.userName ? 'form-control is-invalid': 'form-control'} {...register("userName", {
            required: true,
            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          })}
          id="userInput"
          aria-describedby="userNameHelp"
        />
        {errors.userName?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
        {errors.email?.type === 'pattern' && <p className="invalid-feedback mb-0">Ingresa un email válido</p>}
      </div>
      <Button variant="light" size="lg" className="text-uppercase mt-3 w-100" onClick={() => navigate('/') } >Cancelar</Button>
      <Button
        variant="success"
        size="lg"
        className={errors.userName ? 'text-uppercase is-invalid mt-3 w-100': 'text-uppercase mt-3 w-100'}
        type="submit"
      >
        {isLoading &&
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        }
        {!isLoading && 'Recuperar contraseña'}
      </Button>
      <p className="mt-3 mb-3 text-muted text-center fs-6">
        Global Sales Support/TI Comercial <br />
        Todos los derechos reservados <br />
        &copy; Aeroméxico 2022 <br />
        <small>v1.0.8</small>
      </p>
    </form>
  );

  const formConfirm = () => (
    <form id="loginform" onSubmit={handleSubmit(onSubmitConfirm)}>
      {showMsgError &&
        <Alert
          variant='danger'
          onClose={() => setShowMsgError(false)}
          dismissible
        >
          {errorConfirm && msgErrorConfirm.map(msg => <p>{msg}</p>)}
        </Alert>
      }
      <div className={errors.code ? 'form-control is-invalid mb-4': 'form-control mb-4'}>
        <label htmlFor="codeInput">Código</label>
        <input 
          className={errors.code ? 'form-control is-invalid': 'form-control'} {...register("code", { required: true })}
          id="codeInput"
          aria-describedby="codeInputHelp"
        />
          {errors.code?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
      </div>
      <div className={errors.password ? 'form-control is-invalid mb-4': 'form-control mb-4'}>
        <label htmlFor="passwordInput">Nueva contraseña</label>
        <input 
          type="password"
          className={errors.password ? 'form-control is-invalid': 'form-control'} {...register("password", { required: true })}
          id="passwordInput"
          aria-describedby="passwordInputHelp"
        />
          {errors.password?.type === 'required' && <p className="invalid-feedback mb-0">Campo requerido</p>}
      </div>
      <Button
        variant="success"
        size="lg"
        className={errors.userName ? 'text-uppercase is-invalid mt-3 w-100': 'text-uppercase mt-3 w-100'}
        type="submit"
      >
        {isLoadingConfirm &&
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        }
        {!isLoadingConfirm && 'Recuperar contraseña'}
      </Button>
      <p className="mt-3 mb-3 text-muted text-center fs-6">
        Global Sales Support/TI Comercial <br />
        Todos los derechos reservados <br />
        &copy; Aeroméxico 2022 <br />
        <small>v1.0.8</small>
      </p>
    </form>
  );

  return (
    <main className="form-signin w-100 h-100 m-auto d-flex justify-content-center align-items-center">
      <div className="wrapper m-auto form-card">
        { !showConfirmForm && formEmail() }
        { showConfirmForm && formConfirm() }
      </div>
    </main>
  );
};

export default ResetPasswordForm;