import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/images/logo.svg';
import { useAuth } from '../../hooks';


const NavPrimary = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="/"><img src={logo} alt="Logo"></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0">
              <Nav.Link href="/agency/list">Agencias</Nav.Link>
              <Nav.Link href="/report/create">Reportes</Nav.Link>
              <Nav.Link href="/user/list">Usuarios</Nav.Link>
            </Nav>
            <Nav className="d-flex">
              <Nav.Link href="/">Sistema CSI</Nav.Link>
            </Nav>
            <Nav onSelect={handleLogout} className="d-flex">
              <Nav.Link href='/' className="border-start"><span className="icon-logout me-2"></span>Cerrar Sesión</Nav.Link>
              <span className="version">v1.0.8</span>
            </Nav>
            
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
};

export default NavPrimary;
